<template>
  <div class="view content-padding">
    <TableOverview
      kind="user"
      :settings="settings"
      :footer-buttons="footerButtons"
      :get-page="getUserList"
      :items="items"
      :isDataLoaded="isDataLoaded"
    />

    <router-view name="system_payout_report" :route-back="{ name: 'users' }" />
    <router-view name="user_generate_payout" :route-back="{ name: 'users' }" />
    <router-view name="create_user" :route-back="{ name: 'users' }" />
    <router-view name="edit_user" :route-back="{ name: 'users' }" />
  </div>
</template>

<script>
import TableOverview from "@/views/Dashboard/Common/TableOverview/";
const colors = require("/colors.config.json");
import { mapActions, mapMutations, mapGetters, mapState } from "vuex";

export default {
  name: "Overview",
  components: {
    TableOverview,
  },
  data(){
    return {items: {}, isDataLoaded: false}
  },
  computed: {
    ...mapGetters("helper", {
      hasPermissions: "hasPermissions",
      labels: "labels",
    }),
    ...mapState("helper", {
      admin_roles: "admin_roles",
    }),
    ...mapState("user", ["newUserCreated"]),
    isAllowed() {
      return this.hasPermissions(["super", "system"]);
    },
    footerButtons() {
      let result = [
        {
          label: this.newEntityLabel(),
          variant: "btn-purple",
          actionName: "createNew",
          action: () => {
            if (this.$route.name !== "create-user")
              this.$router.push({ name: "create-user" });
          },
        },
      ];
      
      if (this.$route.params?.kind == 'admin') {
        result.push({
          label: this.labels.payout_report,
          variant: "btn-purple",
          actionName: "payoutReport",
          action: () => {
            if (this.$route.name !== "system-payout-report")
              this.$router.push({ name: "system-payout-report" });
          },
        });
      }

      return result;
    },
    settings() {
      return {
        header: [
          {
            headerLabel: this.labels.username,
            headerColor: `${colors.gray_text}`,
            key: "username",
            computeFn: (input) => input,
            bodyColor: `${colors.dark}`,
            columnWidth: "1 1 30%",
            whiteSpace: "nowrap",
          },
          {
            headerLabel: this.labels.email,
            headerColor: `${colors.gray_text}`,
            key: "email",
            computeFn: (input) => input,
            bodyColor: `${colors.gray_text_2}`,
            columnWidth: "1 1 25%",
            // textAlign: 'center',
          },
        ],
        sortable: false,
        // sortStyles: { flex: '0 0 5%', color: `${colors.gray_text}` },
        minTableWidth: "1000px",
        actionsWidth: "0 0 50%",
        actionsHeaderLabel: "",
        actions: [
          {
            label: this.labels.activate,
            variant: "btn-orange",
            styles: { padding: "8px 15px", fontWeight: "700" },
            action: (e) => this.activate(e._id),
            show: (data) => !this.checkIsActive(data.isActive),
          },
          {
            label: this.labels.deactivate,
            variant: "btn-orange",
            styles: { padding: "8px 15px", fontWeight: "700" },
            action: (e) => this.deactivate(e._id),
            show: (data) => this.checkIsActive(data.isActive),
          },
          {
            label: this.labels.verify,
            variant: "btn-green",
            styles: { padding: "8px 15px", fontWeight: "700" },
            action: (e) => this.verify(e._id),
            show: (data) => !this.checkIsActive(data.role?.object?.isVerified ? data.role.object.isVerified : false),
          },
          {
            label: this.labels.changePassword,
            variant: "btn-navy",
            styles: { padding: "8px 15px", fontWeight: "700" },
            action: (e) => {
              this.$router.push({
                name: "reset-user",
                params: { kind: this.$route.params.kind, userId: e._id },
              });
            },
          },
          {
            label: this.labels.open,
            variant: "btn-navy",
            styles: { padding: "8px 15px", fontWeight: "700" },
            action: (e) => {
              this.$router.push({
                name: "edit-user",
                params: { kind: this.$route.params.kind, userId: e._id },
              });
            },
          },
          {
            label: this.labels.delete,
            variant: "btn-danger",
            styles: { padding: "8px 15px", fontWeight: "700" },
            action: (e) => this.delete(e._id),
          },
        ],
      };
    },
  },
  methods: {
    ...mapMutations("helper", {
      setTablePagination: "setTablePagination",
    }),
    ...mapActions("notification", {
      addNotification: "addNotification",
    }),
    ...mapActions("user", {
      getUsers: "getUsers",
      activateUser: "activateUser",
      deactivateUser: "deactivateUser",
      verifyUser: "verifyUser",
      deleteUser: "deleteUser",
    }),
    ...mapMutations("user", ["setUserStoreStateByKey"]),
    checkIsActive(isActive) {
      return isActive === "yes";
    },
    activate(id) {
      this.activateUser({ id })
        .then((res) => {
          if (res.success) {
            this.getUserList(1);
            this.addNotification({
              variant: "success",
              msg: this.labels.activate,
            });
          } else if (res.error) {
            this.addNotification({
              variant: "danger",
              msg: res.error,
              labels: this.labels,
            });
          }
          return res;
        })
        .catch((err) => console.log(err));
    },
    deactivate(id) {
      this.deactivateUser({ id })
        .then((res) => {
          if (res.success) {
            this.getUserList(1);
            this.addNotification({
              variant: "success",
              msg: this.labels.deactivate,
            });
          } else if (res.error) {
            this.addNotification({
              variant: "danger",
              msg: res.error,
              labels: this.labels,
            });
          }
          return res;
        })
        .catch((err) => console.log(err));
    },
    delete(id) {
      this.deleteUser({ id })
        .then((res) => {
          if (res.success) {
            this.getUserList(1);
            this.addNotification({
              variant: "success",
              msg: this.labels.delete,
            });
          } else if (res.error) {
            this.addNotification({
              variant: "danger",
              msg: res.error,
              labels: this.labels,
            });
          }
          return res;
        })
        .catch((err) => console.log(err));
    },
    verify(id) {
      this.verifyUser({ user: id })
        .then((res) => {
          if (res.success) {
            this.getUserList(1);
            this.addNotification({
              variant: "success",
              msg: this.labels.verify,
            });
          } else if (res.error) {
            this.addNotification({
              variant: "danger",
              msg: res.error,
              labels: this.labels,
            });
          }
          return res;
        })
        .catch((err) => console.log(err));
    },
    getUserList(page) {
			this.isDataLoaded = false
      let data = {
        page,
        kind: this.$capitalize(this.$route.params.kind),
      };
      return this.getUsers(data)
        .then((res) => {
          if (res.success) {
            this.items = res.data.items
            this.isDataLoaded = true
            this.setTablePagination(res.data.pages);
            if (
              Number(this.$route.query.page) !== Number(res.data.pages.current)
            ) {
              this.$router.push({ query: { page: res.data.pages.current } });
            }
          } else if (res.error) {
            this.addNotification({
              variant: "danger",
              msg: res.error,
              labels: this.labels,
            });
          }
          return res;
        })
        .catch((err) => console.log(err));
    },
    newEntityLabel() {
      if (this.$route.params.kind === "admin") {
        return `${this.labels.new} ${this.labels.admin}`;
      }

      return `${this.labels.new} ${this.labels.account}`;
    },
  },
  mounted() {
    this.getUserList(1);
    if (!this.isAllowed && this.admin_roles) {
      this.$router.push({ name: "dashboard" });
    }
  },
  watch: {
    "$route.params.kind"() {
      this.getUserList(1);
    },
    admin_roles() {
      if (!this.isAllowed) {
        this.$router.push({ name: "dashboard" });
      }
    },
    newUserCreated(newUserCreated) {
      if (newUserCreated) {
        this.setUserStoreStateByKey({key: 'newUserCreated', value: false});
        this.getUserList(1);
      }
    }
  },
  updated() {
    // document.querySelector(".modal-backdrop").style.display = "none";
  },
};
</script>

<style lang="scss" scoped></style>
